<template>
	<div class="chat-alerts-coin-parent">
		<svg
			:style="dynamicStyle"
			viewBox="1000 3600 700 550"
		>
		    <defs>
		        <clipPath :id="dynamicId" ref="coin-edge-clip">
		        	<ellipse
			            ref="coin-reverse"
			            cx="1248"
			            cy="3871"
			            rx="63"
			            ry="63"
		        	/>
		            <rect
		            	ref="coin-edge"
		            	x="1184"
		            	y="3871"
		            	width="127"
		            	height="0"
		            />
		        </clipPath>
		    </defs>

		    <g
		    	ref="coin"
		    	class="coin"
		    	opacity="0.6"
		    >
		        <g :clip-path="'url(#'+dynamicId+')'">
		            <rect
		            	:fill="fillCoinEdgeClip"
		            	x="1185"
		            	y="3863"
		            	width="127"
		            	height="100"
		            />
		            <g
		            	opacity="0.8"
		            	:fill="fillCoinEdgeGrooves"
		            >
		                <rect x="1185" y="3863" width="3" height="100" />
		                <rect x="1190" y="3863" width="3" height="100" />
		                <rect x="1200" y="3863" width="3" height="100" />
		                <rect x="1215" y="3863" width="3" height="100" />
		                <rect x="1295" y="3863" width="3" height="100" />
		                <rect x="1303" y="3863" width="3" height="100" />
		            </g>
		        </g>
		        <ellipse
		        	ref="coin-obverse"
		        	:fill="fillCoinObverse"
		        	stroke="#ffd24d"
		        	stroke-width="2"
		        	cx="1248"
		        	cy="3871"
		        	rx="62"
		        	ry="63"
		        />
		    </g>
		</svg>
	</div>
</template>

<script>
	// https://codepen.io/ksenia-k/pen/vPMbwP
	import { gsap, TweenLite, Elastic } from "gsap";
	import { CustomEase } from "gsap/CustomEase";
	gsap.registerPlugin(CustomEase);

	export default {
		props: {
			dataObject: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				fillCoinEdgeClip: '#f48802', //'#c59816',
				fillCoinEdgeGrooves: '#f8a326', //'#ffd24d',
				fillCoinObverse: '#f8cf4e', //#f2cb58', //#ffd24d',

				coin: null,
				coinEdge: null,
				coinObserve: null,
				coinReverse: null,
				dynamicStyle: {
					left: (Math.floor(Math.random() * 70) + 1) + '%',
					transform: 'rotate('+(Math.floor(Math.random() * 2) + -1) + 'deg'+')'
				}
			}
		},
		computed: {
			dynamicId() {
				return 'coin-edge-clip_' + this.dataObject.fbId;
			},
		},
		mounted() {
			this.coin = this.$refs['coin'];
			this.coinEdge = this.$refs['coin-edge'];
			this.coinObserve = this.$refs['coin-obverse'];
			this.coinReverse = this.$refs['coin-reverse'];

			this.dropCoin();

			// PLAY SOUND
			setTimeout(() => {
				this.$store.dispatch('tasks/runTasks', [{ uuid: 'ba9aa7c7-a595-419a-92d1-31aaad9442de' }]); // coins 1
			}, 400);
		},
		methods: {
			dropCoin() {

				let rotationFlag = false;

			    // speed coefficient
			    const dur = 1.2;

			    // Coin flip
			    TweenLite.to([this.coinObserve, this.coinReverse], dur * 0.7, {
			        delay: dur * 0.3,
			        attr: {ry: 4},
			    });
			    TweenLite.to(this.coinReverse, dur * 0.7, {
			        delay: dur * 0.3,
			        attr: {cy: 3891},
			    });
			    TweenLite.to(this.coinEdge, dur * 0.7, {
			        delay: dur * 0.3,
			        attr: {height: 20},
			    });

			    // Coin falling
			    TweenLite.to(this.coin, dur, {
			        y: 1770,
			        ease: CustomEase.create("custom", "M0,0,C0.272,0.096,0.354,0.963,0.362,1,0.37,0.985,0.45,0.686,0.532,0.696,0.622,0.707,0.679,0.981,0.686,0.998,0.704,0.884,0.766,0.87,0.79,0.87,0.838,0.87,0.856,0.985,0.87,0.998,0.881,0.994,0.908,0.938,0.936,0.938,0.972,0.938,1,1,1,1")
			    });

			    // Coin random rotation
			    TweenLite.to(this.coin, dur * 0.1, {
			        delay: dur * 0.1,
			        rotation: (Math.random() * 3 + 3) * (rotationFlag > 0.5 ? 1 : -1),
			    });
			    TweenLite.to(this.coin, dur * 0.8, {
			        delay: 0.2 * dur,
			        rotation: 0,
			        ease: Elastic.easeIn.config(1, 1),

			        // Reset everything when in 1 sec after animation is completed
			    //     onComplete: () => {
			    //         TweenLite.to(this.coin, 4.5, {
			    //             delay: 1,
			    //             attr: { opacity: 0 },
			    //             onComplete: function () {
			    //                 TweenLite.set(this.coin, {
			    //                     y: 0,
			    //                     rotation: 0,
			    //                     attr: { transform: 'matrix(1,0,0,1,0,0)' }
			    //                 });
			    //                 TweenLite.set([this.coinObserve, this.coinReverse], {
			    //                     attr: {ry: 63, cy: 3871},
			    //                 });
			    //                 TweenLite.set(this.coinEdge, {
			    //                     attr: {height: 0},
			    //                 });
			    //                 TweenLite.to(this.coin, 0.2, {
			    //                     attr: { opacity: 1 },
			    //                 });
			    //                 rotationFlag = !rotationFlag;
			    //                 // TweenLite.set([], {
			    //                 //     delay: 1,
			    //                 //     onComplete: this.dropCoin
			    //                 // });
							// }
			    //         });
			    //     }
			    });
			}
		}
	}
</script>

<style lang="scss" scoped>

	.chat-alerts-coin-parent {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		// border: 2px orange dashed;
		// border-right: 3px solid lighten(#ffd24d, 3%);
		// pointer-events: auto;
		// background: desaturate(darken(#ffd24e, 10%), 20%);
	}

	svg {
		position: absolute;
		top: -120%;
		// left: 50%; // 0 - 70%
		width: 250px; // [LOCKED]
		height: calc(220%);
		// border: 2px dashed aqua;

	}

	.coin {
		box-shadow: 0 0 5px 10px red;
	}
</style>